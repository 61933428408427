import { React, useState, useEffect } from "react";
import Footer2 from "./Footer2";
import Header from "./Header";
import HeaderLower from "./HeaderLower";
import "./Button.css";
import { Icon } from "@iconify/react";
import { FaCheckCircle } from "react-icons/fa";
// import assignmentCheck from "@iconify-icons/zmdi/assignment-check";
import { Navigate, useNavigate } from "react-router-dom";
import StorageService from "../services/storage.service";
import LogService from "../services/log.service";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";

function TestOurTech_thankyou(props) {
  const TITLE = "Raymour & Flanigan Cares | Thank You";
  const [website, setWebsite] = useState(window.location.href);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const navigate = useNavigate();
  StorageService.destroyThankStorage();

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  useEffect(() => {
    function timeout() {
      setTimeout(function () {
        localStorage.setItem("returnpage", false);
        localStorage.removeItem("RegisterForm");
        localStorage.removeItem("TestOurTech_thankyou");
        window.location.replace("/register");
        return false;
      }, signoutTime);
    }

    timeout();
  }, [signoutTime]);

  //FIXME
  const isRegister = localStorage.getItem("isRegister");
  if (
    isRegister === "false" ||
    isRegister === null ||
    isRegister === undefined
  ) {
    return <Navigate to="/register" />;
  }
  var registerform = secureLocalStorage.getItem("registerform");
  var RegisterObject = registerform ? JSON.parse(registerform) : false;
  if (typeof RegisterObject === "boolean") {
    return <Navigate to="/register" />;
  } else {
    // let showroom = RegisterObject.showroom ? RegisterObject.showroom : false;
  }
  const myDestory = () => {
    localStorage.removeItem("return_page", false);
    localStorage.removeItem("isRegister");
    secureLocalStorage.removeItem("registerform");
    window.location.replace("/register");
  };
  // if(!RegisterObject.showroom){
  //   console.log("if")

  //   // console.log(RegisterObject.showroom)
  //   // navigate("/register");
  //   // return <Navigate to="/register" />;
  // } else {
  //   console.log("else")

  // }
  // if (localStorage.getItem("return_page") === "true") {
  //   return <Navigate to="/register/registration-thankyou" />;
  // }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div className="white-box thank">
          <h1 className="text-center">Thank You! </h1>
          {/* <Icon icon={assignmentCheck} /> */}
          <FaCheckCircle />
          <p style={{ fontFamily: "Nimbus Sans" }}>
            The customer will receive an email with their unique code from our
            fulfillment center. Once the customer will receive an email within
            72 hours and be given instructions on redeeming their Reward Card. 
            For further information please call{" "}
            <a href="tel:1-866-ELITE-21">1-866-ELITE-21</a> or email us at{" "}
            <a href="mailto:customercare@eliterewards.biz">
              customercare@eliterewards.biz
            </a>{" "}
            and one of our customer service representatives will be happy to
            assist you.
          </p>
          <a
            href="javascript:void(0)"
            onClick={() => myDestory()}
            id="Thanku_Buttons"
            className="btn btn-md btn-primary2"
          >
            Go Back
          </a>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default TestOurTech_thankyou;
