import React from "react";
import "./HeaderLower.css";
function HeaderLower() {
  return (
    <></>
    // <div className="header_lower">
    //   Raymour & Flanigan and Elite Rewards are proud sponsors of Make A Wish.
    // </div>
  );
}
export default HeaderLower;
